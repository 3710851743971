
document.addEventListener( 'wpcf7mailsent', function( event ) {
    if (window.gtag) {
        gtag('event', 'Formulier', {
            'event_category': 'Contact',
            'event_label': 'Verzonden'
        });
    }
}, false );

$(window).scroll(function(){
    parallaxPercentage();
});

$(window).on('resize', function(){
    parallaxPercentage();
});

jQuery(document).ready(function(){

    jQuery('section:not(.accordion) iframe').each(function(){
        loadFrame($(this));
    });

    jQuery('.block-testimonials .slider').slick({
        dots: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 5000,
        cssEase: 'ease-in-out'
    });

    jQuery('.block-logobar .slider').slick({
        dots: false,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 5000,
        cssEase: 'ease-in-out',
        centerMode: true,
        variableWidth: true,
        slidesToShow: 3
    });

    AOS.init({
        easing: 'ease',
        duration: 400,
        once: false,
        mirror: false,
        disable: false
    });

    //jQuery('.videobg').youtube_background();

    jQuery('.menu-button').click(function() {
        jQuery('body').toggleClass('menu-expanded menu-collapsed lock-scroll');
        //topbarState();
    });

    jQuery('.mainmenu .level1').click(function() {
        //$('.mainmenu li').removeClass('expanded');
        jQuery(this).parent().addClass('clicked');

        jQuery('.mainmenu li').each(function( index, element ) {
            if ( jQuery(this).hasClass('clicked') ) {
                jQuery(this).removeClass('clicked').toggleClass('expanded')
            } else {
                jQuery(this).removeClass('expanded');
            }
        });
    });

    jQuery('.mainmenu li button').click(function() {
        //console.log('back');
        jQuery('.mainmenu li').removeClass('expanded');
    });

    // explore button home
    jQuery('.downlink').click(function() {
        jQuery('html, body').stop().animate({
            'scrollTop': jQuery('div.flexcontent').offset().top
        }, 750, 'swing', function () {
            //window.location.hash = target;
        });
    });

    // accordion
    jQuery('.ac-bar').click(function() {
        jQuery(this).closest('.ac-item').addClass('clicked');
        jQuery(this).closest('.accordion').find('.ac-item').each(function(){
            if(jQuery(this).hasClass('active')) {
                jQuery(this).addClass('animate').removeClass('clicked active');
                var $this = jQuery(this);
                setTimeout(function() {
                    $this.removeClass('animate');
                }, 200);
            } else if(jQuery(this).hasClass('clicked')){
                jQuery(this).addClass('animate');
                jQuery(this).find('iframe').each( function(){
                    loadFrame(jQuery(this));
                });
                var $this = jQuery(this);
                setTimeout(function() {
                    $this.toggleClass('active').removeClass('clicked animate');
                }, 250);
            }
        });
    });
    // term accordion
    jQuery('.term-title').click(function() {
        jQuery(this).closest('.term').addClass('clicked');
        jQuery(this).closest('.termlist').find('.term').each(function(){

            if(jQuery(this).hasClass('active')) {
                //console.log($(this).find('.term-title').html());
                jQuery(this).addClass('animate').removeClass('clicked active');
                var $this = $(this);
                setTimeout(function() {
                    $this.removeClass('animate');
                }, 200);
            } else if(jQuery(this).hasClass('clicked')){
                //console.log($(this).find('.term-title').html());
                jQuery(this).addClass('animate');
                var $this = jQuery(this);
                setTimeout(function() {
                    $this.toggleClass('active').removeClass('clicked animate');
                        }, 250);
            }
        });
    });


    if(window.location.hash){
        //console.log(location.hash);
        var hash = window.location.hash.substring(1);

        // create active term
        if ( jQuery("#term-"+hash).length ) {
            jQuery("#term-"+hash).addClass('active');
        }
        // create active accordeon
        if ( jQuery("#ac-"+hash).length ) {
            //jQuery("#ac-"+hash).addClass('active');
            jQuery("#ac-"+hash+' .ac-bar').trigger( "click" );
        }

        if(!hash.startsWith("&gid") && jQuery('a[id='+hash+']').length){
            jQuery('html, body').stop().animate({
                //'scrollTop': jQuery('a[id='+hash+']').offset().top -120
                'scrollTop': jQuery('a[id='+hash+']').offset().top -100
            }, 750, 'swing', function () {
                //window.location.hash = target;
            });
        }

    }else {
        //console.log('no #');
    }

    /*
     * Google Analytics events for mailto and tel links
     */
    jQuery("[href*='tel:'], [href*='mailto:'], [href*='whatsapp:']").click(function(e) {
        var href = jQuery(this).attr('href');

        // tel:
        if (href.toLowerCase().indexOf("tel:") >= 0) {
            eventCategory = 'Call';
            eventLabel = href.replace('tel:', '');
        }

        // mailto:
        if (href.toLowerCase().indexOf("mailto:") >= 0) {
            eventCategory = 'E-mail';
            eventLabel = href.replace('mailto:', '');
        }

        // whatsapp:
        if (href.toLowerCase().indexOf("api.whatsapp.com") >= 0) {
            eventCategory = 'Whatsapp';
            eventLabel = '-';
        }
        //console.log('GA Event: ' + eventCategory + ' - ' + eventLabel);

        if (window.gtag) {
            e.preventDefault();

            //ga('send', 'event', eventCategory, 'Click', eventLabel);
            gtag('event', 'Click', {
                'event_category': eventCategory,
                'event_label': eventLabel
            });

            setTimeout(function() {
                window.location = href;
            }, 500);
        }
    });
});

function loadFrame($this){
    var url = $this.data('url');
    var framesrc = $this.attr('src');

    if ( (typeof framesrc == typeof undefined || framesrc === false) && url !== false) {
        $this.attr('src', url);
    }
}

/*
function myFunction() {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}*/

function searchFilter() {
    // Declare variables
    var input, filter, list, ul, li, a, i, txtValue;
    input = $('#searchFilterInput');
    //filter = input.value.toUpperCase();
    filter = $('#searchFilterInput').val().toUpperCase();
    //ul = document.getElementById("myUL");
    //li = ul.getElementsByTagName('li');
    list = $('.termlist');

    list.find('.term').each(function( index ) {
        txtValue = $( this ).find('.term-title').text();
        console.log( index + ": " +  txtValue);
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            //li[i].style.display = "";
            $( this ).removeClass('hide');
        } else {
            //li[i].style.display = "none";
            $( this ).removeClass('active').addClass('hide');
        }
    });
    AOS.refresh();

    // Loop through all list items, and hide those who don't match the search query
    /*for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }*/
}

function parallaxPercentage(){
    vpBottom = Math.round($(window).scrollTop() + $(window).height());
    vpTop = Math.round($(window).scrollTop());

    $('.animation').each(function( index  ) {
        offset = $(this).offset();
        posIn = Math.round(offset.top);
        posOut = Math.round(offset.top + $(this).height());

        /*n = offset.top - $(window).height();
        s='';
        s += 'scrolltop: '+$(window).scrollTop();
        s += ', window height: '+$(window).height();
        s += ', elm height: '+ $(this).height();
        s += ', n: '+ n;
        s += ', posIn: '+ posIn;
        s += ', posOut: '+ posOut;
        console.log(s);*/

        if(posIn > vpBottom || posOut < vpTop){
            $(this).attr("data-visible", 'n');
            parallaxpercentage = 0;
        } else {
            parallaxpercentage = ( $(window).scrollTop() - offset.top + $(window).height()) / ($(window).height() + $(this).height()) *100;
            $(this).attr("data-visible", 'y');
            f = $(this).data('animfunction');
            eval("anim" + f + "(parallaxpercentage)");
        }
        $(this).attr("data-pp", parallaxpercentage);
    });
}

function animbgimage(perc){
    x = perc/100; // [0-1]
    bgtop = -40 + (40*x)+'%';
    $('#bgimage .bgimage').css('top', bgtop);
}

function animdossiers(perc){
    fig = $('#dossiers');
    //p = fig.attr('data-pp');

    x = perc/100; // [0-1]
    r = 1-x; // [1-0]
    /*bg = 'rgba(255,0,0,'+x+')';
    console.log(bg);
    fig.css('backgroundColor', bg);
    fig.text(perc);*/

    bgtop = -40 + (40*x)+'%';
    $('#dossiers #bg').css('top', bgtop);

    l2top = -45 + (30*r)+'%';
    $('#dossiers #layer2').css('top', l2top);

    l3top = -35 + (90*r)+'%';
    $('#dossiers #layer3').css('top', l3top);
}

function animtools(perc) {
    fig = $('#tools');
    //p = fig.attr('data-pp');
    //scaleToFit($('#tools .fit-centered'), 743, 605);

    x = perc / 100; // [0-1]
    r = 1 - x; // [1-0]
    rad = 0.017453292519943;

    bgtop = -29 + (25*x)+'%';
    $('#tools #bg').css('top', bgtop);

    l1top = -110 + (170*r)+'%';
    $('#tools #layer1').css('background-position-y', l1top);

    l2top = 50 + (100*r)+'%';
    $('#tools #layer2').css('background-position-y', l2top);

    l3top = 30 + (250*r)+'%';
    $('#tools #layer3').css('background-position-y', l3top);

    l4top = -10 + (130*r)+'%';
    $('#tools #layer4').css('background-position-y', l4top);

    /*p1 = perc+30;
    scale1 = 1 + 1.5*Math.sin(p1*1.8*rad);
    $('#tools #icon1').css('transform', 'scale('+scale1+')');
    p2 = perc+15;
    scale2 = 1 + 1.5*Math.sin(p2*1.8*rad);
    $('#tools #icon2').css('transform', 'scale('+scale2+')');
    p3 = perc-10;
    scale3 = 1 + 1.5*Math.sin(p3*1.8*rad);
    $('#tools #icon3').css('transform', 'scale('+scale3+')');
    p4 = perc-30;
    scale4 = 1 + 1.5*Math.sin(p4*1.8*rad);
    $('#tools #icon4').css('transform', 'scale('+scale4+')');*/
}
